import React from "react";
import { useQueryState } from "~/hooks/useQueryState";
import { z } from "zod";

import { WidgetLoadingPlaceholder } from "~devlink/WidgetLoadingPlaceholder";
import { EventCalendarContainer } from "~devlink/EventCalendarContainer";
import { EventDepartmentFilters } from "~devlink/EventDepartmentFilters";
import { EventCalendarGrid } from "~devlink/EventCalendarGrid";
import { EventChip } from "~devlink/EventChip";

import { trpc } from "~/utils/trpc";

import { isExpired } from "~/utils/eventUtils";
import type { DepartmentData, EventOccurrenceData } from "~/types";

import FeaturedEvent from "./components/FeaturedEvent";
import DepartmentFilter from "./components/DepartmentFilter";
import Event from "./components/Event";

const Calendar = ({
  start,
  end,
  placeholderImage,
  omitEventIds = [],
  itemsPerPage,
  showFeatured = false,
  showDepartmentFilters = false,
  linkTemplate = (id) => `#${id}`,
}: {
  start?: string;
  end?: string;
  placeholderImage?: string;
  omitEventIds?: number[];
  itemsPerPage?: number;
  showFeatured?: boolean | number;
  showDepartmentFilters?: boolean;
  linkTemplate?(data: EventOccurrenceData): string;
}) => {
  const departmentsByGroupId =
    trpc.settings.ccb.departmentsByGroupId.get.useQuery();
  const departmentColors = trpc.settings.ccb.departmentColors.get.useQuery();
  const calendarQuery = trpc.ccb.calendar.useQuery(
    {
      start,
      end,
    },
    { enabled: departmentsByGroupId.isSuccess && departmentColors.isSuccess }
  );
  const [selectedDepartments, setSelectedDepartments] = useQueryState<string[]>(
    "d",
    []
  );

  if (calendarQuery.isPending) return <WidgetLoadingPlaceholder />;

  const data = calendarQuery.data?.filter(
    (item: EventOccurrenceData) => !omitEventIds.includes(item.eventId)
  );

  const featuredEvents = showFeatured
    ? data
        ?.filter((item: EventOccurrenceData) => {
          if (isExpired(item.end)) return false;
          return item.event.description.includes("#featured");
        })
        .slice(0, typeof showFeatured === "number" ? showFeatured : 1)
    : [];

  const availableDepartments = (
    data?.reduce((acc, cur) => {
      if (!departmentsByGroupId.data) return acc;
      const department = departmentsByGroupId.data.find(
        ({ id }) => id === cur.event.group.id
      )?.department;
      if (!department) return acc;
      if (acc.some(({ id }) => id === department?.id)) return acc;
      return [...acc, department];
    }, [] as DepartmentData[]) || []
  ).sort((a, b) => a.id - b.id);

  const departmentFilters = showDepartmentFilters
    ? selectedDepartments
        .map((id) => {
          return availableDepartments.find(
            (department) => department.id === z.coerce.number().int().parse(id)
          );
        })
        .filter((department) => department !== undefined)
    : [];

  const filteredData = data
    ?.filter((item: EventOccurrenceData) => {
      if (departmentFilters.length === 0) return true;
      const department = departmentsByGroupId.data?.find(
        ({ id }) => id === item.event.group.id
      )?.department;
      if (!department) return false;
      return departmentFilters.some(({ id }) => id === department.id);
    })
    .slice(0, itemsPerPage);

  return (
    <EventCalendarContainer>
      {featuredEvents?.map((item: EventOccurrenceData, index: number) => {
        return (
          <FeaturedEvent
            key={index}
            data={item}
            placeholderImage={placeholderImage || ""}
            linkTemplate={linkTemplate}
          />
        );
      })}
      {showDepartmentFilters && (
        <EventDepartmentFilters>
          {availableDepartments.map((department, index) => {
            if (!department) return;
            return (
              <DepartmentFilter
                key={index}
                department={department}
                departmentFilters={departmentFilters}
                selectDepartment={(id) => {
                  setSelectedDepartments([...selectedDepartments, z.coerce.string().parse(id)]);
                }}
                deselectDepartment={(id) => {
                  setSelectedDepartments(
                    selectedDepartments.filter(
                      (departmentId) => departmentId !== z.coerce.string().parse(id)
                    )
                  );
                }}
              />
            );
          })}
          <div
            style={
              {
                cursor: "pointer",
                "--chip-background-color": "transparent",
                "--chip-border-color": "transparent",
                "--chip-text-color": "#0B4A6F",
              } as React.CSSProperties
            }
            onClick={() => {
              setSelectedDepartments([]);
            }}
          >
            <EventChip name="Reset Filter(s)" />
          </div>
        </EventDepartmentFilters>
      )}
      <EventCalendarGrid>
        {filteredData &&
          filteredData.map((item: EventOccurrenceData, index: number) => {
            return (
              <Event
                key={index}
                data={item}
                placeholderImage={placeholderImage || ""}
                departmentsByGroupId={departmentsByGroupId.data || []}
                departmentColors={departmentColors.data || []}
                linkTemplate={linkTemplate}
              />
            );
          })}
      </EventCalendarGrid>
    </EventCalendarContainer>
  );
};
export type CalendarProps = Parameters<typeof Calendar>[0];

export default Calendar;
