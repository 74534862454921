"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./EventsList.module.css";

export function EventsList({ as: _Component = _Builtin.Block, children }) {
  return (
    <_Component
      className={_utils.cx(_styles, "events-list-container")}
      tag="div"
    >
      {children}
    </_Component>
  );
}
