import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import Widget, { type WidgetOptions } from "./widgets/Widget";

export const createWidget = (
  rootSelector: string,
  options: WidgetOptions,
) => {
  if (!rootSelector) {
    throw new Error("First argument (root selector) is required");
  }
  if (!options) {
    throw new Error("Second argument (options) is required");
  }
  createRoot(document.querySelector(rootSelector)!).render(
    <StrictMode>
      <Widget {...options} />
    </StrictMode>
  );
}
