import { useQuery } from "@tanstack/react-query";

import { SermonsGrid } from "~devlink/SermonsGrid";
import { SermonCard } from "~devlink/SermonCard";

import {
  SermonsQueryResult,
  type Sermon,
  type SermonMedia,
} from "./types-validation";

const ctaLabel = (media: SermonMedia | undefined) => {
  const mediaTypes: string[] =
    media?.reduce<string[]>((acc, cur) => {
      return [...acc, cur.type];
    }, []) ?? [];
  if (mediaTypes.includes("vimeo") || mediaTypes.includes("youtube")) {
    return "Watch Now";
  }
  if (mediaTypes.includes("audio") || mediaTypes.includes("soundcloud")) {
    return "Listen Now";
  }
  return "Read More";
};

const Sermons = ({
  apiEndpointUrl,
  filter = () => true,
  limit = null,
  placeholderImage,
}: {
  apiEndpointUrl: string;
  filter: (sermon: Sermon) => boolean;
  limit: number | null;
  placeholderImage: string;
}) => {
  const sermonsQuery = useQuery({
    queryKey: ["sermons"],
    queryFn: async () => {
      const response = await fetch(apiEndpointUrl);
      const result = await response.json();
      const data = SermonsQueryResult.parse(result);
      return data;
    },
  });

  return (
    <SermonsGrid>
      {sermonsQuery.data?.items
        .filter(filter)
        .slice(0, limit || sermonsQuery.data.items.length)
        .map((sermon, index) => (
          <SermonCard
            key={index}
            title={sermon.title}
            series={sermon.series?.title ?? ""}
            speaker={sermon.speakers?.[0].name ?? ""}
            speakerImage={sermon.speakers?.[0].photo?.url ?? placeholderImage}
            date={sermon.date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
            ctaLabel={ctaLabel(sermon.media)}
            link={{ href: sermon.url, target: "_blank" }}
          />
        ))}
    </SermonsGrid>
  );
};
export type SermonsProps = Parameters<typeof Sermons>[0];

export default Sermons;
