"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FullEventCta.module.css";

export function FullEventCta({
  as: _Component = _Builtin.VFlex,

  link = {
    href: "#",
    target: "_blank",
  },

  label = "Sign Up",
  heading = "Want to join?",
}) {
  return (
    <_Component className={_utils.cx(_styles, "full-event-cta")} tag="div">
      <_Builtin.Heading
        className={_utils.cx(_styles, "full-event-cta-heading")}
        tag="h3"
      >
        {heading}
      </_Builtin.Heading>
      <_Builtin.Link
        className={_utils.cx(_styles, "full-event-cta-button")}
        button={true}
        block=""
        options={link}
      >
        {label}
      </_Builtin.Link>
    </_Component>
  );
}
