import { EventCard } from "~devlink/EventCard";
import { EventCardExpired } from "~devlink/EventCardExpired";
import { EventChip } from "~devlink/EventChip";

import {
  isExpired,
  eventDateLabel,
  eventTimeLabel,
  eventAddressLabel,
} from "../utils";
import type {
  DepartmentsByGroupIdData,
  DepartmentColorsData,
  EventOccurrenceData,
} from "~/types";
import { getDepartmentColors } from "~/utils/getDepartmentColors";

const Event = ({
  data,
  placeholderImage,
  departmentsByGroupId,
  departmentColors,
  linkTemplate,
}: {
  data: EventOccurrenceData;
  placeholderImage: string;
  departmentsByGroupId: DepartmentsByGroupIdData;
  departmentColors: DepartmentColorsData;
  linkTemplate: (event: EventOccurrenceData) => string;
}) => {
  if (!data.occurrence) return;
  if (!data.event) return;
  const { event } = data;
  const department = departmentsByGroupId.find(
    (group) => group.id === event.group.id
  )?.department;
  const styles = getDepartmentColors({
    department,
    departmentsByGroupId,
    departmentColors,
  });
  return isExpired(data.end) ? (
    <EventCardExpired
      image={event.images?.medium || placeholderImage}
      name={event.name}
      date={eventDateLabel(data)}
      time={eventTimeLabel(data)}
      location={
        data.event.address
          ? eventAddressLabel({ address: data.event.address })
          : "Grace Church of Mentor"
      }
      link={{ href: linkTemplate(data) }}
    >
      <div style={styles}>
        {department && <EventChip name={department.name} />}
      </div>
    </EventCardExpired>
  ) : (
    <EventCard
      image={event.images?.medium || placeholderImage}
      name={event.name}
      date={eventDateLabel(data)}
      time={eventTimeLabel(data)}
      location={
        data.event.address
          ? eventAddressLabel({ address: data.event.address })
          : "Grace Church of Mentor"
      }
      link={{ href: linkTemplate(data) }}
    >
      <div style={styles}>
        {department && <EventChip name={department.name} />}
      </div>
    </EventCard>
  );
};

export default Event;
