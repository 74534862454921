import type { DepartmentData, DepartmentsByGroupIdData, DepartmentColorsData } from "~/types";

export const getDepartmentColors = ({
  department, departmentColors,
}: {
  department: DepartmentData | undefined;
  departmentsByGroupId: DepartmentsByGroupIdData;
  departmentColors: DepartmentColorsData;
}) => {
  const defaultColors = departmentColors.find(
    ({ departmentId }) => departmentId === 0
  ) || {
    departmentId: 0,
    backgroundColor: "#ffffff",
    borderColor: "#000000",
    textColor: "#000000",
  };

  if (!department) return defaultColors;

  const colors = departmentColors.find((c) => c.departmentId === department.id);

  if (colors) {
    return {
      "--chip-background-color": colors.backgroundColor,
      "--chip-border-color": colors.borderColor,
      "--chip-text-color": colors.textColor,
    } as React.CSSProperties;
  }

  return {
    "--chip-background-color": defaultColors.backgroundColor,
    "--chip-border-color": defaultColors.borderColor,
    "--chip-text-color": defaultColors.textColor,
  } as React.CSSProperties;
};
