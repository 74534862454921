import { WidgetLoadingPlaceholder } from "~devlink/WidgetLoadingPlaceholder";
import { EventsList } from "~devlink/EventsList";
import { EventsListItem } from "~devlink/EventsListItem";

import { trpc } from "~/utils/trpc";

import { eventDateLabel, eventStartTimeLabel, eventAddressLabel } from "~/utils/eventUtils";
import type { EventOccurrenceData, DepartmentData } from "~/types";

type EventOccurrenceDataWithDepartment = EventOccurrenceData & {
  department?: DepartmentData;
};

const Events = ({
  start,
  end,
  filter = () => true,
  omitEventIds = [],
  limit,
  linkTemplate = (id) => `#${id}`,
}: {
  start?: string;
  end?: string;
  filter?: (data: EventOccurrenceDataWithDepartment) => boolean;
  omitEventIds?: number[];
  limit?: number;
  linkTemplate?(data: EventOccurrenceDataWithDepartment): string;
}) => {
  const departmentsByGroupId =
    trpc.settings.ccb.departmentsByGroupId.get.useQuery();
  const calendarQuery = trpc.ccb.calendar.useQuery(
    {
      start,
      end,
    },
    { enabled: departmentsByGroupId.isSuccess }
  );

  if (calendarQuery.isPending) return <WidgetLoadingPlaceholder />;

  const data = calendarQuery.data && calendarQuery.data
    .map((item: EventOccurrenceDataWithDepartment) => {
      return { ...item, department: departmentsByGroupId.data?.find(
        ({ id }) => id == item.event.group.id
      )?.department}
    })
    .filter(filter)
    .filter((item: EventOccurrenceDataWithDepartment) => !omitEventIds.includes(item.eventId))
    .slice(0, limit);

  if (data?.length === 0) return (
    <div>No events found.</div>
  );

  return (
    <EventsList>
      {data?.map((item: EventOccurrenceDataWithDepartment, index: number) => {
        const { name } = item.event;
        const { department } = item;
        // const department = departmentsByGroupId.data?.find(
        //   ({ id }) => id === item.event.group.id
        // )?.department;
        return (
          <EventsListItem
            key={index}
            name={name}
            date={eventDateLabel(item)}
            time={eventStartTimeLabel(item)}
            location={
              item.event.address
                ? eventAddressLabel({ address: item.event.address })
                : "Grace Church of Mentor"
            }
            department={department?.name}
            link={{ href: linkTemplate(item) }}
          />
        );
      })}
    </EventsList>
  );
};

export type EventsProps = Parameters<typeof Events>[0];

export default Events;
