import type { Address } from "~/types";

export const isExpired = (date: Date) => {
  const now = new Date();
  return now > date;
};

export const eventDateLabel = ({ start }: { start: Date }) => {
  return new Date(start).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

export const eventTimeLabel = ({ start, end }: { start: Date; end: Date }) => {
  if (
    start.getHours() === 0 &&
    start.getMinutes() === 0 &&
    end.getHours() === 23 &&
    end.getMinutes() === 59
  ) {
    return "All Day Event";
  } else {
    return `${start.toLocaleTimeString("en-US", {
      timeStyle: "short",
    })} - ${end.toLocaleTimeString("en-US", {
      timeStyle: "short",
    })}`;
  }
};

export const eventAddressLabel = ({ address }: { address: Address; }) => {
  return address.name;
};