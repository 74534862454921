"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./SermonCard.module.css";

export function SermonCard({
  as: _Component = _Builtin.Link,
  speakerImage = "",
  title = "Title",
  speaker = "Speaker",
  series = "Series",
  date = "January 1, 1970",
  ctaLabel = "Watch Now",

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "sermon-card")}
      id={_utils.cx(
        _styles,
        "w-node-_181e2408-66c8-aadf-7f55-85438894879e-8894879e"
      )}
      button={false}
      block="inline"
      options={link}
    >
      <_Builtin.Image
        className={_utils.cx(_styles, "sermon-speaker-image")}
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={speakerImage}
      />
      <_Builtin.VFlex
        className={_utils.cx(_styles, "sermon-details")}
        tag="div"
      >
        <_Builtin.Heading
          className={_utils.cx(_styles, "heading-style-h5")}
          tag="h3"
        >
          {title}
        </_Builtin.Heading>
        <_Builtin.Block
          className={_utils.cx(_styles, "text-size-regular")}
          tag="div"
        >
          {speaker}
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "text-size-regular")}
          tag="div"
        >
          {series}
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "text-size-tiny")}
          tag="div"
        >
          {date}
        </_Builtin.Block>
      </_Builtin.VFlex>
      <_Builtin.Block className={_utils.cx(_styles, "sermon-cta")} tag="div">
        <_Builtin.Block className={_utils.cx(_styles, "text-none")} tag="div">
          {ctaLabel}
        </_Builtin.Block>
        <_Builtin.HtmlEmbed
          className={_utils.cx(_styles, "icon-1x1-small")}
          value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M4.16699%2010.9574H15.8337M15.8337%2010.9574L10.0003%205.12402M15.8337%2010.9574L10.0003%2016.7907%22%20stroke%3D%22%233F621A%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
        />
      </_Builtin.Block>
    </_Component>
  );
}
