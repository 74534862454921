const items = [
  { name: "Calendar", href: "/calendar/" },
  { name: "Event (with params)", href: "/event/?id=9396&occurrence=20241102" },
  { name: "Event (no params)", href: "/event/" },
  { name: "Sermons", href: "/sermons/" },
];

const TableOfContents = () => {
  return (
    <>
      <h2>Examples</h2>
      <ul>
        {items.map(({ name, href }) => (
          <li key={href}>
            <a href={href}>{name}</a>
          </li>
        ))}
      </ul>
    </>
  );
};
export type TableOfContentsProps = object;

export default TableOfContents;
