import parse from "html-react-parser";
import { z } from "zod";

import type { RouterOutput } from "@repo/trpc";

import { useQueryState } from "~/hooks/useQueryState";
import { trpc } from "~/utils/trpc";
import { getDepartmentColors } from "~/utils/getDepartmentColors";

import { WidgetLoadingPlaceholder } from "~devlink/WidgetLoadingPlaceholder";
import { FullEvent } from "~devlink/FullEvent";
import { FullEventNotFound } from "~devlink/FullEventNotFound";
import { EventChip } from "~devlink/EventChip";
import { FullEventCta } from "~devlink/FullEventCta";

type EventData = NonNullable<RouterOutput["ccb"]["event"]>;
type Address = EventData["address"];

const eventLocationLabel = ({ address }: { address?: Address }) => {
  if (!address) {
    return "Grace Church of Mentor";
  }
  const addressString = [
    address.name.trim(),
    address.street.trim(),
    address.city.trim(),
    address.state.trim(),
  ]
    .filter((v) => v !== null)
    .join(", ");
  const mapUrl = new URL("https://maps.google.com/");
  mapUrl.searchParams.set("q", addressString);
  return (
    <>
      {addressString}{" "}
      <a href={mapUrl.toString()} target="_blank" rel="noreferrer">
        View Map
      </a>
    </>
  );
};

const eventDateLabel = ({ start }: { start: Date }) => {
  return new Date(start).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

const eventTimeLabel = ({
  start,
  end,
  all_day,
}: {
  start: Date;
  end: Date;
  all_day: boolean;
}) => {
  if (all_day) {
    return "All Day Event";
  }
  return `${start.toLocaleTimeString("en-US", {
    timeStyle: "short",
  })} - ${end.toLocaleTimeString("en-US", {
    timeStyle: "short",
  })}`;
};

const Event = () => {
  const [id] = useQueryState("id", null);
  const [occurrence] = useQueryState("occurrence", null);

  if (id === null) return <FullEventNotFound />;

  const departmentsByGroupId =
    trpc.settings.ccb.departmentsByGroupId.get.useQuery();
  const departmentColors = trpc.settings.ccb.departmentColors.get.useQuery();
  const eventQuery = trpc.ccb.event.useQuery(
    {
      id: z.coerce.number().int().parse(id),
      occurrence: occurrence || undefined,
    },
    { enabled: id !== null && departmentsByGroupId.isSuccess && departmentColors.isSuccess }
  );

  if (eventQuery.isPending) return <WidgetLoadingPlaceholder />;
  if (eventQuery.isError) return <FullEventNotFound />;

  const department = departmentsByGroupId.data?.find(
    ({ id }) => id === eventQuery.data.group.id
  )?.department;

  return (
    <FullEvent
      name={eventQuery.data?.name}
      description={eventQuery.isSuccess && parse(eventQuery.data.description)}
      hasDescription={
        eventQuery.isSuccess && eventQuery.data.description !== null
      }
      image={eventQuery.data?.images?.large}
      hasImage={eventQuery.data?.images !== undefined}
      organizer={eventQuery.data?.owner?.name}
      date={eventQuery.isSuccess && eventDateLabel(eventQuery.data)}
      time={eventQuery.isSuccess && eventTimeLabel(eventQuery.data)}
      location={eventQuery.isSuccess && eventLocationLabel(eventQuery.data)}
      departments={
        department && (
          <div
            style={getDepartmentColors({
              department,
              departmentsByGroupId: departmentsByGroupId.data || [],
              departmentColors: departmentColors.data || [],
            })}
          >
            <EventChip name={department.name} />
          </div>
        )
      }
      ctaItems={
        eventQuery.isSuccess &&
        eventQuery.data.registration?.url &&
        !eventQuery.data.registration.closed && (
          <FullEventCta
            label="Sign Up"
            link={{
              href: eventQuery.data.registration.url,
              target: "_blank",
            }}
          />
        )
      }
    />
  );
};
export type EventProps = object;
// export type EventProps = Parameters<typeof Event>[0];

export default Event;
