"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./EventDepartmentFilters.module.css";

export function EventDepartmentFilters({
  as: _Component = _Builtin.HFlex,
  children,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "event-department-filters")}
      tag="div"
    >
      {children}
    </_Component>
  );
}
