import { useCallback } from "react";
import queryString from "query-string";
import { useLocation } from "./useLocation";

const parse = (value: string) => {
  return queryString.parse(value, {
    decode: true,
    arrayFormat: "bracket-separator",
    arrayFormatSeparator: ",",
    // parseNumbers: true,
    // parseBooleans: true,
  });
};
type QueryObject = ReturnType<typeof parse>;
type QueryParam = QueryObject[string];

const stringify = (value: Parameters<typeof queryString.stringify>[0]) => {
  return queryString.stringify(value, {
    encode: true,
    arrayFormat: "bracket-separator",
    arrayFormatSeparator: ",",
    skipNull: true,
    skipEmptyString: true,
  });
};

export const useQueryState = <T extends QueryParam | undefined>(
  queryKey: string,
  defaultValue: T
): [T, (value: T) => void] => {
  const { pathname, search, push } = useLocation();

  const getQueryState = useCallback(() => {
    const value = parse(search)[queryKey];
    if (value === undefined) return defaultValue as T;
    return value as T;
  }, [queryKey, search, defaultValue]);

  const setQueryState = useCallback(
    (value: T) => {
      const existingQueries = parse(search);
      const string = stringify({ ...existingQueries, [queryKey]: value });
      push(`${pathname}?${string}`);
    },
    [queryKey, pathname, search, push]
  );

  return [getQueryState() as T, setQueryState as (value: T) => void];
};
