"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FullEventNotFound.module.css";

export function FullEventNotFound({ as: _Component = _Builtin.VFlex }) {
  return (
    <_Component className={_utils.cx(_styles, "full-event-wrapper")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "full-event-heading")}
        tag="div"
      >
        <_Builtin.Heading
          className={_utils.cx(_styles, "heading-style-h2-copy")}
          tag="h2"
        >
          {"Event Not Found"}
        </_Builtin.Heading>
        <_Builtin.HFlex
          className={_utils.cx(_styles, "full-event-department-row")}
          tag="div"
        />
        <_Builtin.Paragraph
          className={_utils.cx(_styles, "text-size-medium", "text-color-gray")}
        >
          {
            "We couldn't find the event you requested. Try again later, or visit our "
          }
          <_Builtin.Link
            button={false}
            block=""
            options={{
              href: "#",
            }}
          >
            {"events page"}
          </_Builtin.Link>
          {" to browse all events."}
        </_Builtin.Paragraph>
      </_Builtin.Block>
    </_Component>
  );
}
