"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./WidgetLoadingPlaceholder.module.css";

export function WidgetLoadingPlaceholder({ as: _Component = _Builtin.Block }) {
  return (
    <_Component
      className={_utils.cx(_styles, "widget-loading-placeholder")}
      tag="div"
    >
      <_Builtin.HtmlEmbed
        className={_utils.cx(_styles, "code-embed")}
        value="%3C!--%20Loader%204%20--%3E%0A%0A%3Csvg%20version%3D%221.1%22%20id%3D%22L4%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%20%20viewBox%3D%220%200%2052%2012%22%20enable-background%3D%22new%200%200%200%200%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%3Ccircle%20fill%3D%22currentColor%22%20stroke%3D%22none%22%20cx%3D%226%22%20cy%3D%226%22%20r%3D%226%22%3E%0A%20%20%20%20%3Canimate%0A%20%20%20%20%20%20attributeName%3D%22opacity%22%0A%20%20%20%20%20%20dur%3D%221s%22%0A%20%20%20%20%20%20values%3D%220%3B1%3B0%22%0A%20%20%20%20%20%20repeatCount%3D%22indefinite%22%0A%20%20%20%20%20%20begin%3D%220.1%22%2F%3E%20%20%20%20%0A%20%20%3C%2Fcircle%3E%0A%20%20%3Ccircle%20fill%3D%22currentColor%22%20stroke%3D%22none%22%20cx%3D%2226%22%20cy%3D%226%22%20r%3D%226%22%3E%0A%20%20%20%20%3Canimate%0A%20%20%20%20%20%20attributeName%3D%22opacity%22%0A%20%20%20%20%20%20dur%3D%221s%22%0A%20%20%20%20%20%20values%3D%220%3B1%3B0%22%0A%20%20%20%20%20%20repeatCount%3D%22indefinite%22%20%0A%20%20%20%20%20%20begin%3D%220.2%22%2F%3E%20%20%20%20%20%20%20%0A%20%20%3C%2Fcircle%3E%0A%20%20%3Ccircle%20fill%3D%22currentColor%22%20stroke%3D%22none%22%20cx%3D%2246%22%20cy%3D%226%22%20r%3D%226%22%3E%0A%20%20%20%20%3Canimate%0A%20%20%20%20%20%20attributeName%3D%22opacity%22%0A%20%20%20%20%20%20dur%3D%221s%22%0A%20%20%20%20%20%20values%3D%220%3B1%3B0%22%0A%20%20%20%20%20%20repeatCount%3D%22indefinite%22%20%0A%20%20%20%20%20%20begin%3D%220.3%22%2F%3E%20%20%20%20%20%0A%20%20%3C%2Fcircle%3E%0A%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
