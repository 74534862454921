import { EventChip } from "~devlink/EventChip";

import type { DepartmentData } from "~/types";

const DepartmentFilter = ({
  department,
  departmentFilters,
  selectDepartment,
  deselectDepartment,
}: {
  department: DepartmentData;
  departmentFilters: DepartmentData[];
  selectDepartment: (departmentId: number) => void;
  deselectDepartment: (departmentId: number) => void;
}) => {
  const isSelected = departmentFilters.includes(department);
  const styles = {
    cursor: "pointer",
    "--chip-background-color": isSelected ? "#0B4A6F" : "#F9FAFB",
    "--chip-border-color": isSelected ? "#0B4A6F" : "#0000001a",
    "--chip-text-color": isSelected ? "#FFFFFF" : "#000000cc",
  } as React.CSSProperties;
  const handleClick = () => {
    if (isSelected) {
      deselectDepartment(department.id);
      // setSelectedDepartments(departmentFilters.filter((d) => d !== department));
    } else {
      selectDepartment(department.id);
      // setSelectedDepartments([...departmentFilters, department]);
    }
  };
  return (
    <div style={styles} onClick={handleClick}>
      <EventChip name={department.name} />
    </div>
  );
};

export default DepartmentFilter;
