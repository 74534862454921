import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import SuperJSON from "superjson";

import { trpc } from "~/utils/trpc";

import Calendar, { type CalendarProps } from "./Calendar";
import Events, { type EventsProps } from "./Events";
import Event, { type EventProps } from "./Event";
import Sermons, { type SermonsProps } from "./Sermons";
import TableOfContents, { type TableOfContentsProps } from "./TableOfContents";

type WidgetWithoutQueryProps = {
  queryType: "none"
} & (
  | { type: "tableOfContents" }
);

type WidgetWithTRPCQueryProps = {
  queryType: "trpc-react-query";
  apiEndpointUrl: string;
} & (
  | { type: "calendar" } & CalendarProps
  | { type: "event" } & EventProps
);

type WidgetWithReactQueryProps = {
  queryType: "react-query";
  apiEndpointUrl: string;
} & (
  | { type: "sermons" } & SermonsProps
);

export type WidgetOptions =
  | WidgetWithoutQueryProps
  | WidgetWithTRPCQueryProps
  | WidgetWithReactQueryProps;

const widgetTypes = {
  tableOfContents: (props: TableOfContentsProps) => <TableOfContents {...props} />,
  calendar: (props: CalendarProps) => <Calendar {...props} />,
  events: (props: EventsProps) => <Events {...props} />,
  event: (props: EventProps) => <Event {...props} />,
  sermons: (props: SermonsProps) => <Sermons {...props} />,
};

const WidgetWithTRPCQuery = ({ type, apiEndpointUrl, ...options }: Omit<WidgetWithTRPCQueryProps, "queryType">) => {
  const queryClient = new QueryClient();
  const trpcClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: apiEndpointUrl,
        transformer: SuperJSON,
      }),
    ],
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {Object.hasOwn(widgetTypes, type) && widgetTypes[type](options)}
      </QueryClientProvider>
    </trpc.Provider>
  );
};

const WidgetWithReactQuery = ({ type, ...options }: Omit<WidgetWithReactQueryProps, "queryType">) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {Object.hasOwn(widgetTypes, type) && widgetTypes[type](options)}
    </QueryClientProvider>
  );
};

const Widget = ({ type, queryType, ...options }: WidgetOptions) => {
  if (queryType === "trpc-react-query") {
    return <WidgetWithTRPCQuery type={type} {...options as Omit<WidgetWithTRPCQueryProps, "queryType" | "type">} />;
  }
  if (queryType === "react-query") {
    return <WidgetWithReactQuery type={type} {...options as Omit<WidgetWithReactQueryProps, "queryType" | "type">} />;
  }

  return Object.hasOwn(widgetTypes, type) && widgetTypes[type](options as WidgetWithoutQueryProps);
};

export default Widget;
