import { z } from "zod";

export const SermonMedia = z.array(z.object({
  type: z.enum(["audio", "youtube", "vimeo", "soundcloud", "embed"]),
  url: z.string().url(),
}));
export type SermonMedia = z.infer<typeof SermonMedia>;

export const Sermon = z.object({
  date: z.coerce.date(),
  featured: z.boolean().optional(),
  media: SermonMedia.optional(),
  series: z.object({
    title: z.string().optional(),
  }).optional(),
  speakers: z.array(z.object({
    name: z.string(),
    photo: z.object({
      url: z.string().url(),
    }).optional(),
  })).optional(),
  title: z.string(),
  url: z.string().url(),
});
export type Sermon = z.infer<typeof Sermon>;

export const SermonsQueryResult = z.object({
  items: z.array(Sermon),
});
export type SermonsQueryResult = z.infer<typeof SermonsQueryResult>;
