"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./SermonsGrid.module.css";

export function SermonsGrid({ as: _Component = _Builtin.Block, children }) {
  return (
    <_Component className={_utils.cx(_styles, "sermons-grid")} tag="div">
      {children}
    </_Component>
  );
}
