"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./EventCard.module.css";

export function EventCard({
  as: _Component = _Builtin.Link,
  name = "Event Name",
  image = "https://cdn.prod.website-files.com/66baaabc75398e2a2577b06a/66c759b9cec77091d94a5945_Image.png",
  date = "Monday, September 23",
  time = "6:00 PM - 9:00 PM",
  location = "Grace Church of Mentor",
  eventStatus,
  eventId,
  groupId,
  departmentId,
  runtimeProps,
  children,

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "event-card")}
      id={_utils.cx(
        _styles,
        "w-node-ccb41b9d-5aea-fad3-ddfe-a7035c293a7b-5c293a7b"
      )}
      button={false}
      event-id={eventId}
      group-id={groupId}
      department-id={departmentId}
      event-status={eventStatus}
      block="inline"
      options={link}
      {...runtimeProps}
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "event-image-wrapper")}
        tag="div"
      >
        <_Builtin.Image
          className={_utils.cx(_styles, "event-image")}
          loading="auto"
          width="Auto"
          height="auto"
          alt=""
          src={image}
        />
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "event-content")} tag="div">
        <_Builtin.VFlex
          className={_utils.cx(_styles, "event-information")}
          tag="div"
        >
          <_Builtin.HFlex
            className={_utils.cx(_styles, "event-name")}
            tag="div"
          >
            <_Builtin.Heading
              className={_utils.cx(_styles, "event-heading")}
              tag="h5"
            >
              {name}
            </_Builtin.Heading>
            <_Builtin.HtmlEmbed
              className={_utils.cx(_styles, "event-link-icon")}
              value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M5.83301%2015.1234L14.1663%206.79004M14.1663%206.79004H5.83301M14.1663%206.79004V15.1234%22%20stroke%3D%22currentColor%09%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
            />
          </_Builtin.HFlex>
          <_Builtin.VFlex
            className={_utils.cx(_styles, "event-details")}
            tag="div"
          >
            <_Builtin.HFlex
              className={_utils.cx(_styles, "event-details-row")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "event-details-icon")}
                value="%3Csvg%20width%3D%2214%22%20height%3D%2216%22%20viewBox%3D%220%200%2014%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M13%206.66683H1M9.66667%201.3335V4.00016M4.33333%201.3335V4.00016M4.2%2014.6668H9.8C10.9201%2014.6668%2011.4802%2014.6668%2011.908%2014.4488C12.2843%2014.2571%2012.5903%2013.9511%2012.782%2013.5748C13%2013.147%2013%2012.5869%2013%2011.4668V5.86683C13%204.74672%2013%204.18667%2012.782%203.75885C12.5903%203.38252%2012.2843%203.07656%2011.908%202.88482C11.4802%202.66683%2010.9201%202.66683%209.8%202.66683H4.2C3.0799%202.66683%202.51984%202.66683%202.09202%202.88482C1.71569%203.07656%201.40973%203.38252%201.21799%203.75885C1%204.18667%201%204.74672%201%205.86683V11.4668C1%2012.5869%201%2013.147%201.21799%2013.5748C1.40973%2013.9511%201.71569%2014.2571%202.09202%2014.4488C2.51984%2014.6668%203.0799%2014.6668%204.2%2014.6668Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
                is-placeholder=""
              >
                {date}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.HFlex
              className={_utils.cx(_styles, "event-details-row")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "event-details-icon")}
                value="%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M7.9987%204.00016V8.00016L10.6654%209.3335M14.6654%208.00016C14.6654%2011.6821%2011.6806%2014.6668%207.9987%2014.6668C4.3168%2014.6668%201.33203%2011.6821%201.33203%208.00016C1.33203%204.31826%204.3168%201.3335%207.9987%201.3335C11.6806%201.3335%2014.6654%204.31826%2014.6654%208.00016Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
              >
                {time}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.HFlex
              className={_utils.cx(_styles, "event-details-row")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "event-details-icon")}
                value="%3Csvg%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6.0013%208.3335C7.10587%208.3335%208.0013%207.43807%208.0013%206.3335C8.0013%205.22893%207.10587%204.3335%206.0013%204.3335C4.89673%204.3335%204.0013%205.22893%204.0013%206.3335C4.0013%207.43807%204.89673%208.3335%206.0013%208.3335Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M6.0013%2014.6668C7.33464%2012.0002%2011.3346%2010.279%2011.3346%206.66683C11.3346%203.72131%208.94682%201.3335%206.0013%201.3335C3.05578%201.3335%200.667969%203.72131%200.667969%206.66683C0.667969%2010.279%204.66797%2012.0002%206.0013%2014.6668Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
              >
                {location}
              </_Builtin.Block>
            </_Builtin.HFlex>
          </_Builtin.VFlex>
          <_Builtin.HFlex
            className={_utils.cx(_styles, "event-department")}
            tag="div"
          >
            {children}
          </_Builtin.HFlex>
        </_Builtin.VFlex>
      </_Builtin.Block>
    </_Component>
  );
}
