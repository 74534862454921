"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./EventsListItem.module.css";

export function EventsListItem({
  as: _Component = _Builtin.Link,
  department = "Whole Church",
  name = "Event Name",
  date = "January 1, 1970",
  time = "12:00 AM",
  location = "Grace Church of Mentor",

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "events-list-item")}
      button={false}
      block="inline"
      options={link}
    >
      <_Builtin.HFlex
        className={_utils.cx(_styles, "events-list-item-nav")}
        tag="div"
      >
        <_Builtin.HFlex
          className={_utils.cx(_styles, "events-list-item-nav-link")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "events-list-item-department")}
            tag="div"
          >
            {department}
          </_Builtin.Block>
        </_Builtin.HFlex>
        <_Builtin.HFlex
          className={_utils.cx(_styles, "events-list-item-nav-link")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "events-list-item-detail-text")}
            tag="div"
          >
            {"Details"}
          </_Builtin.Block>
          <_Builtin.HtmlEmbed
            className={_utils.cx(_styles, "events-list-item-detail-icon")}
            value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M5.83301%2015.1234L14.1663%206.79004M14.1663%206.79004H5.83301M14.1663%206.79004V15.1234%22%20stroke%3D%22%233F621A%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
          />
        </_Builtin.HFlex>
      </_Builtin.HFlex>
      <_Builtin.Heading
        className={_utils.cx(_styles, "events-list-item-heading")}
        tag="h3"
      >
        {name}
      </_Builtin.Heading>
      <_Builtin.Grid
        className={_utils.cx(_styles, "events-list-item-details")}
        tag="div"
      >
        <_Builtin.HFlex
          className={_utils.cx(_styles, "events-list-item-detail")}
          id={_utils.cx(
            _styles,
            "w-node-_6ccd0104-7cb0-1d99-fb25-1d1cc7a4d87a-7a3812ce"
          )}
          tag="div"
        >
          <_Builtin.HtmlEmbed
            className={_utils.cx(_styles, "events-list-item-detail-icon")}
            value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20clip-path%3D%22url(%23calendar_clip0-2154-1064)%22%3E%0A%3Cpath%20d%3D%22M17.5%209.29069H2.5M13.3333%202.62402V5.95736M6.66667%202.62402V5.95736M6.5%2019.2907H13.5C14.9001%2019.2907%2015.6002%2019.2907%2016.135%2019.0182C16.6054%2018.7785%2016.9878%2018.3961%2017.2275%2017.9257C17.5%2017.3909%2017.5%2016.6908%2017.5%2015.2907V8.29069C17.5%206.89056%2017.5%206.19049%2017.2275%205.65571C16.9878%205.18531%2016.6054%204.80286%2016.135%204.56317C15.6002%204.29069%2014.9001%204.29069%2013.5%204.29069H6.5C5.09987%204.29069%204.3998%204.29069%203.86502%204.56317C3.39462%204.80286%203.01217%205.18531%202.77248%205.65571C2.5%206.19049%202.5%206.89056%202.5%208.29069V15.2907C2.5%2016.6908%202.5%2017.3909%202.77248%2017.9257C3.01217%2018.3961%203.39462%2018.7785%203.86502%2019.0182C4.3998%2019.2907%205.09987%2019.2907%206.5%2019.2907Z%22%20stroke%3D%22black%22%20stroke-%3D%22%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22calendar_clip0-2154-1064%22%3E%0A%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate(0%200.957031)%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "events-list-item-detail-text")}
            tag="div"
          >
            {date}
          </_Builtin.Block>
        </_Builtin.HFlex>
        <_Builtin.HFlex
          className={_utils.cx(_styles, "events-list-item-detail")}
          id={_utils.cx(
            _styles,
            "w-node-_5e370e75-48cd-332d-f224-d38f47117d8c-7a3812ce"
          )}
          tag="div"
        >
          <_Builtin.HtmlEmbed
            className={_utils.cx(_styles, "events-list-item-detail-icon")}
            value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20clip-path%3D%22url(%23clock_clip0-2154-1068)%22%3E%0A%3Cpath%20d%3D%22M10.0003%205.95736V10.9574L13.3337%2012.624M18.3337%2010.9574C18.3337%2015.5597%2014.6027%2019.2907%2010.0003%2019.2907C5.39795%2019.2907%201.66699%2015.5597%201.66699%2010.9574C1.66699%206.35498%205.39795%202.62402%2010.0003%202.62402C14.6027%202.62402%2018.3337%206.35498%2018.3337%2010.9574Z%22%20stroke%3D%22black%22%20stroke-%3D%22%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clock_clip0-2154-1068%22%3E%0A%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate(0%200.957031)%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "events-list-item-detail-text")}
            tag="div"
          >
            {time}
          </_Builtin.Block>
        </_Builtin.HFlex>
        <_Builtin.HFlex
          className={_utils.cx(_styles, "events-list-item-detail")}
          id={_utils.cx(
            _styles,
            "w-node-_0c1b2358-809a-f363-f9f7-9cba51279a05-7a3812ce"
          )}
          tag="div"
        >
          <_Builtin.HtmlEmbed
            className={_utils.cx(_styles, "events-list-item-detail-icon")}
            value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20clip-path%3D%22url(%23marker-pin-02_clip0-2154-1072)%22%3E%0A%3Cpath%20d%3D%22M9.99967%2011.374C11.3804%2011.374%2012.4997%2010.2547%2012.4997%208.87402C12.4997%207.49331%2011.3804%206.37402%209.99967%206.37402C8.61896%206.37402%207.49967%207.49331%207.49967%208.87402C7.49967%2010.2547%208.61896%2011.374%209.99967%2011.374Z%22%20stroke%3D%22black%22%20stroke-%3D%22%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.99967%2019.2907C11.6663%2015.9574%2016.6663%2013.8059%2016.6663%209.29069C16.6663%205.60879%2013.6816%202.62402%209.99967%202.62402C6.31778%202.62402%203.33301%205.60879%203.33301%209.29069C3.33301%2013.8059%208.33301%2015.9574%209.99967%2019.2907Z%22%20stroke%3D%22black%22%20stroke-%3D%22%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22marker-pin-02_clip0-2154-1072%22%3E%0A%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate(0%200.957031)%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "events-list-item-detail-text")}
            tag="div"
          >
            {location}
          </_Builtin.Block>
        </_Builtin.HFlex>
      </_Builtin.Grid>
    </_Component>
  );
}
