"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FullEvent.module.css";

export function FullEvent({
  as: _Component = _Builtin.VFlex,
  name = "Event Title",
  departments,
  image = "",
  location = "Crescent Park, 2500 Evergreen Avenue, Cleveland, OH, 4500",
  date = "July 26, 2024",
  time = "8:00 PM- 11:00 PM",
  organizer = "Organizer Name",
  ctaItems,
  description = (
    <>
      {
        "Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id."
      }
      <br />
      <br />
      {
        "Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."
      }
    </>
  ),
  hasImage = true,
  hasDescription = true,
}) {
  return (
    <_Component className={_utils.cx(_styles, "full-event-wrapper")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "full-event-heading")}
        tag="div"
      >
        <_Builtin.Heading
          className={_utils.cx(_styles, "heading-style-h2-copy")}
          tag="h2"
        >
          {name}
        </_Builtin.Heading>
        <_Builtin.HFlex
          className={_utils.cx(_styles, "full-event-department-row")}
          tag="div"
        >
          {departments}
        </_Builtin.HFlex>
      </_Builtin.Block>
      {hasImage ? (
        <_Builtin.Image
          className={_utils.cx(_styles, "full-event-cover-image")}
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={image}
        />
      ) : null}
      <_Builtin.Grid
        className={_utils.cx(_styles, "full-event-information")}
        tag="div"
      >
        <_Builtin.VFlex
          className={_utils.cx(_styles, "full-event-details")}
          id={_utils.cx(
            _styles,
            "w-node-be514e4c-bc51-0281-c8e3-3da228aaff46-28aaff3e"
          )}
          tag="div"
        >
          <_Builtin.VFlex
            className={_utils.cx(
              _styles,
              "full-event-details-item",
              "full-width"
            )}
            tag="div"
          >
            <_Builtin.HFlex
              className={_utils.cx(_styles, "full-event-details-item-heading")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "full-event-details-icon")}
                value="%3Csvg%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6.0013%208.3335C7.10587%208.3335%208.0013%207.43807%208.0013%206.3335C8.0013%205.22893%207.10587%204.3335%206.0013%204.3335C4.89673%204.3335%204.0013%205.22893%204.0013%206.3335C4.0013%207.43807%204.89673%208.3335%206.0013%208.3335Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M6.0013%2014.6668C7.33464%2012.0002%2011.3346%2010.279%2011.3346%206.66683C11.3346%203.72131%208.94682%201.3335%206.0013%201.3335C3.05578%201.3335%200.667969%203.72131%200.667969%206.66683C0.667969%2010.279%204.66797%2012.0002%206.0013%2014.6668Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
              >
                {"Location"}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.Block
              className={_utils.cx(_styles, "full-event-details-item-text")}
              tag="div"
            >
              {location}
            </_Builtin.Block>
          </_Builtin.VFlex>
          <_Builtin.VFlex
            className={_utils.cx(_styles, "full-event-details-item")}
            tag="div"
          >
            <_Builtin.HFlex
              className={_utils.cx(_styles, "full-event-details-item-heading")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "full-event-details-icon")}
                value="%3Csvg%20width%3D%2214%22%20height%3D%2216%22%20viewBox%3D%220%200%2014%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M13%206.66683H1M9.66667%201.3335V4.00016M4.33333%201.3335V4.00016M4.2%2014.6668H9.8C10.9201%2014.6668%2011.4802%2014.6668%2011.908%2014.4488C12.2843%2014.2571%2012.5903%2013.9511%2012.782%2013.5748C13%2013.147%2013%2012.5869%2013%2011.4668V5.86683C13%204.74672%2013%204.18667%2012.782%203.75885C12.5903%203.38252%2012.2843%203.07656%2011.908%202.88482C11.4802%202.66683%2010.9201%202.66683%209.8%202.66683H4.2C3.0799%202.66683%202.51984%202.66683%202.09202%202.88482C1.71569%203.07656%201.40973%203.38252%201.21799%203.75885C1%204.18667%201%204.74672%201%205.86683V11.4668C1%2012.5869%201%2013.147%201.21799%2013.5748C1.40973%2013.9511%201.71569%2014.2571%202.09202%2014.4488C2.51984%2014.6668%203.0799%2014.6668%204.2%2014.6668Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
                is-placeholder=""
              >
                {"Date"}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.Block
              className={_utils.cx(_styles, "full-event-details-item-text")}
              tag="div"
            >
              {date}
            </_Builtin.Block>
          </_Builtin.VFlex>
          <_Builtin.VFlex
            className={_utils.cx(_styles, "full-event-details-item")}
            tag="div"
          >
            <_Builtin.HFlex
              className={_utils.cx(_styles, "full-event-details-item-heading")}
              tag="div"
            >
              <_Builtin.HtmlEmbed
                className={_utils.cx(_styles, "full-event-details-icon")}
                value="%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M7.9987%204.00016V8.00016L10.6654%209.3335M14.6654%208.00016C14.6654%2011.6821%2011.6806%2014.6668%207.9987%2014.6668C4.3168%2014.6668%201.33203%2011.6821%201.33203%208.00016C1.33203%204.31826%204.3168%201.3335%207.9987%201.3335C11.6806%201.3335%2014.6654%204.31826%2014.6654%208.00016Z%22%20stroke%3D%22currentColor%22%20stroke-width%3D%221.33333%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
              />
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
              >
                {"Time"}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.Block
              className={_utils.cx(_styles, "full-event-details-item-text")}
              tag="div"
            >
              {time}
            </_Builtin.Block>
          </_Builtin.VFlex>
          <_Builtin.VFlex
            className={_utils.cx(
              _styles,
              "full-event-details-item",
              "full-width"
            )}
            tag="div"
          >
            <_Builtin.HFlex
              className={_utils.cx(_styles, "full-event-details-item-heading")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "event-details-text")}
                tag="div"
              >
                {"Organizer(s)"}
              </_Builtin.Block>
            </_Builtin.HFlex>
            <_Builtin.Block
              className={_utils.cx(_styles, "full-event-details-item-text")}
              tag="div"
            >
              {organizer}
            </_Builtin.Block>
          </_Builtin.VFlex>
        </_Builtin.VFlex>
        <_Builtin.VFlex
          className={_utils.cx(_styles, "full-event-cta-wrapper")}
          id={_utils.cx(
            _styles,
            "w-node-be514e4c-bc51-0281-c8e3-3da228aaff62-28aaff3e"
          )}
          tag="div"
        >
          {ctaItems}
        </_Builtin.VFlex>
      </_Builtin.Grid>
      {hasDescription ? (
        <_Builtin.VFlex
          className={_utils.cx(_styles, "full-event-description")}
          tag="div"
        >
          <_Builtin.Heading
            className={_utils.cx(_styles, "full-event-description-heading")}
            tag="h3"
          >
            {"Event Information"}
          </_Builtin.Heading>
          <_Builtin.Block
            className={_utils.cx(_styles, "full-event-description-body")}
            tag="div"
          >
            {description}
          </_Builtin.Block>
        </_Builtin.VFlex>
      ) : null}
    </_Component>
  );
}
