import { FeaturedEventCard } from "~devlink/FeaturedEventCard";

import { eventDateLabel, eventTimeLabel, eventAddressLabel } from "~/utils/eventUtils";
import type { EventOccurrenceData } from "~/types";

const FeaturedEvent = ({
  data,
  placeholderImage,
  linkTemplate,
}: {
  data: EventOccurrenceData;
  placeholderImage: string;
  linkTemplate: (data: EventOccurrenceData) => string;
}) => {
  return (
    <FeaturedEventCard
      image={data.event.images?.large || placeholderImage}
      name={data.event.name}
      date={eventDateLabel(data)}
      time={eventTimeLabel(data)}
      location={
        data.event.address
          ? eventAddressLabel({ address: data.event.address })
          : "Grace Church of Mentor"
      }
      link={{ href: linkTemplate(data) }}
    />
  );
}

export default FeaturedEvent;
