"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./EventChip.module.css";

export function EventChip({
  as: _Component = _Builtin.Block,
  name = "Department",
  className,
  styles,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "event-department-chip")}
      tag="div"
    >
      {name}
    </_Component>
  );
}
